@use '@angular/material' as mat;
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "colors";

$my-primary: mat.define-palette(mat.$blue-grey-palette, 600);
$my-accent: mat.define-palette(mat.$blue-palette, 700);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  density: 0,
));

@include mat.all-component-themes($my-theme);

.mat-card-title {
  line-height: 1.3em;
  color: $primary !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}


.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

