@import "colors";

html, body, li {
  color: $primary;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

li{
  list-style: none;
}

a:hover{
  color: $primary !important;
}

body {
  margin: 0;
  overflow-x: hidden;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

.title {
  vertical-align: middle;
  text-align: left;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 2px;
}

.subtitle {
  line-height: 0.5em !important;
  color: $primary !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.clickable {
  color: $primary;
  cursor: pointer;
}

.clickable:hover {
  color: $accent;
}

.dropdown-menu {
  padding: 10px;
}

.primary {
  color: $primary;
}

.warn {
  color: $warn;
}

.accent {
  color: $warn;
}
