@import "colors";

.p-tree {
  width: 100% !important;
  border: hidden !important;
  background-color: inherit !important;
  color: $primary !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.p-treenode-content:focus {
  color:$accent;
  box-shadow: none!important;
}
